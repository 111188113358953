import * as React from 'react';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import BlockIcon from '@mui/icons-material/Block';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import { EPOS_STATUS_NAMES, imageUnavailable, kioskOffline, kioskStarting } from 'const';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const useStyles = makeStyles((theme: Theme) => ({
    noImage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        height: '100%',
        color: theme.palette.grey[500]
    },
    icon: {
        fontSize: '2.5rem'
    },
    title: {
        fontSize: '1rem',
        paddingTop: theme.spacing(0.5)
    },
    image: {
        width: '100%'
    }
}));

const TerminalsTilesItem = (props: {
    img: string;
    classes?: any;
}) => {
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);
    const classes = useStyles();
    const { img } = props;
    const [ showImage, setShowImage ] = React.useState(true);

    React.useEffect(() => setShowImage(true), [img]);

    const handleError = () => {
        setShowImage(false);
    };

    return (
        img === EPOS_STATUS_NAMES.OFFLINE || img === EPOS_STATUS_NAMES.STARTING
            ? (
                <div className={classes.noImage}>
                    <BlockIcon color="disabled" classes={{ root: classes.icon }} />
                    <p className={classes.title}>{translations[img === EPOS_STATUS_NAMES.OFFLINE ? kioskOffline : kioskStarting]}</p>
                </div>)
            : (
                img && showImage
                    ? (
                        <img
                            className={classes.image}
                            src={img}
                            onError={handleError}
                        />)
                    : (
                        <div className={classes.noImage}>
                            <DesktopAccessDisabledIcon color="disabled" classes={{ root: classes.icon }} />
                            <p className={classes.title}>{translations[imageUnavailable]}</p>
                        </div>)
            )
    );
};

export default TerminalsTilesItem;
