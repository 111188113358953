import * as React from 'react';
import clsx from 'clsx';

import { Divider, Tab, Tabs } from '@mui/material';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { palette } from 'theme';
import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { PENDING_MAINTENANCE_COLOR, STARTING_COLOR } from 'const';

interface IComponentProps {
    errors: number;
    normal: number;
    starting: number;
    offline: number;
    all: number;
    pendingMaintenance: number;
    maintenance: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
        padding: `0 ${theme.spacing(6)}`,

        [theme.breakpoints.down('lg')]: {
            padding: `0 ${theme.spacing(2)}`,
        },
        [theme.breakpoints.down('sm')]: {
            padding: `0 ${theme.spacing(1.5)}`,
        }
    },
    statusBar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `${theme.spacing(2)} 0`,
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(1)} 0`
        }
    },
    button: {
        width: '30%',
        minWidth: 'auto',
        height: theme.spacing(1.5),
        border: 'none',
        borderRadius: '4px',
        marginLeft: '3px',
        marginRight: '3px',
        cursor: 'pointer',
        outline: 'none',
        '&:first-child': {
            marginLeft: '0'
        },
        '&:last-child': {
            marginRight: '0'
        },
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(1)
        }
    },
    [`button-${eposStatuses.errors}`]: (props: IComponentProps) => ({
        width: `${(props.errors / props.all) * 100}%`,
        backgroundColor: palette.error.main,
        '&:hover': {
            backgroundColor: 'rgba(224, 78, 80, 0.7)'
        }
    }),
    [`button-${eposStatuses.normal}`]: (props: IComponentProps) => ({
        width: `${(props.normal / props.all) * 100}%`,
        backgroundColor: palette.secondary.main,
        '&:hover': {
            backgroundColor: palette.secondary.light
        }
    }),
    [`button-${eposStatuses.starting}`]: (props: IComponentProps) => ({
        width: `${(props.starting / props.all) * 100}%`,
        backgroundColor: STARTING_COLOR,
        '&:hover': {
            backgroundColor: `lighten(${STARTING_COLOR}, 0.2)`
        }
    }),
    [`button-${eposStatuses.maintenance}`]: (props: IComponentProps) => ({
        width: `${(props.maintenance / props.all) * 100}%`,
        backgroundColor: theme.palette.grey[400],
        '&:hover': {
            backgroundColor: theme.palette.grey[300]
        }
    }),
    [`button-${eposStatuses.pendingMaintenance}`]: (props: IComponentProps) => ({
        width: `${(props.pendingMaintenance / props.all) * 100}%`,
        backgroundColor: PENDING_MAINTENANCE_COLOR,
        '&:hover': {
            backgroundColor: '#c0a041'
        }
    }),
    [`button-${eposStatuses.offline}`]: (props: IComponentProps) => ({
        width: `${(props.offline / props.all) * 100}%`,
        backgroundColor: palette.text.secondary,
        '&:hover': {
            backgroundColor: 'grey'
        }
    }),
    tab: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        position: 'relative',
        minWidth: 'auto',
        minHeight: '40px',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderRadius: '4px',
        textTransform: 'none',
        '& > span': {
            textTransform: 'none',
        },
        '&::before': {
            content: '\'\'',
            width: '9px',
            height: '8px',
            marginRight: '8px',
            borderRadius: '2px'
        },
        '&:hover': {
            color: palette.primary.main,
            backgroundColor: palette.primary.light,
            borderRadius: '4px 4px 0 0'
        },

        [theme.breakpoints.down('sm')]: {
            minHeight: '30px',
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1)
        }
    },
    allTab: {
        '&::before': {
            display: 'none'
        }
    },
    [`tab-${eposStatuses.errors}`]: {
        '&::before': {
            backgroundColor: palette.error.main
        }
    },
    [`tab-${eposStatuses.normal}`]: {
        '&::before': {
            backgroundColor: palette.secondary.main
        }
    },
    [`tab-${eposStatuses.offline}`]: {
        '&::before': {
            backgroundColor: palette.text.secondary
        }
    },
    [`tab-${eposStatuses.maintenance}`]: {
        '&::before': {
            backgroundColor: theme.palette.grey[400]
        }
    },
    [`tab-${eposStatuses.pendingMaintenance}`]: {
        '&::before': {
            backgroundColor: PENDING_MAINTENANCE_COLOR
        }
    },
    [`tab-${eposStatuses.starting}`]: {
        '&::before': {
            backgroundColor: STARTING_COLOR
        }
    }
}));

export enum eposStatuses {
    all,
    errors,
    normal,
    starting,
    offline,
    maintenance,
    pendingMaintenance,
}

const eposStatusesLabels: Record<eposStatuses, string> = {
    [eposStatuses.all]: 'hm-filter-all',
    [eposStatuses.errors]: 'hm-filter-warning',
    [eposStatuses.normal]: 'hm-filter-normal',
    [eposStatuses.starting]: 'hm-filter-starting',
    [eposStatuses.offline]: 'hm-filter-offline',
    [eposStatuses.maintenance]: 'hm-filter-under-maintenance',
    [eposStatuses.pendingMaintenance]: 'hm-filter-pending-maintenance',
};

type eposStatusesProps = { [key in eposStatuses]: eposStatuses };

const StatusBar = (props: { eposStatusesCount: eposStatusesProps; activeStatus: number; onStatusBarFiltering(num): void }) => {
    const eposStatusesCount = props.eposStatusesCount;
    const all = eposStatusesCount[eposStatuses.all];
    const errors = eposStatusesCount[eposStatuses.errors];
    const normal = eposStatusesCount[eposStatuses.normal];
    const starting = eposStatusesCount[eposStatuses.starting];
    const offline = eposStatusesCount[eposStatuses.offline];
    const maintenance = eposStatusesCount[eposStatuses.maintenance];
    const pendingMaintenance = eposStatusesCount[eposStatuses.pendingMaintenance];

    const classes = useStyles({ maintenance, errors, normal, offline, pendingMaintenance, starting, all });
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    function onStatusBarClick(id: number | string) {
        props.onStatusBarFiltering(id);
    }

    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        props.onStatusBarFiltering(newValue);
    }

    return (
        <>
            {all
                ? (
                    <div className={classes.wrap}>
                        <div className={classes.statusBar}>
                            {Object.keys(eposStatusesCount).map((status) => {
                                return (
                                    eposStatusesCount[status] && status !== String(eposStatuses.all)
                                        ? (
                                            <button
                                                key={status}
                                                className={clsx(classes.button, classes?.[`button-${status}`])}
                                                onClick={() => onStatusBarClick(Number(status))}
                                            />)
                                        : null);
                            })}
                        </div>
                        <div>
                            <Tabs
                                value={props.activeStatus}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons={false}
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab className={clsx(classes.tab, classes.allTab)} label={`${translations['hm-filter-all']} ${all}`} />
                                {Object.keys(eposStatusesCount).map((status) => {
                                    const label = translations[eposStatusesLabels[status]];

                                    return (
                                        eposStatusesCount[status] && status !== String(eposStatuses.all) &&
                                            <Tab
                                                key={status}
                                                className={clsx(classes.tab, classes?.[`tab-${status}`])}
                                                label={label}
                                                value={Number(status)}
                                            />);
                                })}
                            </Tabs>
                        </div>
                    </div>)
                : null}
            <Divider />
        </>);
};

export default StatusBar;
